import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import RecipeHero from "../components/RecipeHero"
import { FaAngleDoubleRight } from "react-icons/fa"
import SEO from "../components/SEO"
import "./RecipeTemplate.css"

const ComponentName = ({ data }) => {
  const {
    title,
    image,
    instructions,
    ingredients,
    link,
    description,
  } = data.recipe
  return (
    <Layout>
      <SEO title={title} description={description} />
      <RecipeHero />
      <div className="accent row">
        <section className=" white center-align recipe-page col s12 m8 offset-m2 z-depth-2">
          <h2 className="title">
            <span>{title}</span>
          </h2>
          <div className="video-box">
            <div className="videoWrapper">
              <iframe src={link} frameBorder="0"></iframe>
            </div>
          </div>
          <div className="row main-text">
            <div className="col s10 m8 l4 offset-s1 offset-m2 offset-l0 ingredients-container teal lighten-4">
              <h4>Ingredients</h4>
              {ingredients.map(item => {
                return (
                  <div className="single-ingredient" key={item.id}>
                    <FaAngleDoubleRight className="prefix"></FaAngleDoubleRight>
                    <p>
                      {item.item} - <span> {item.amount}</span>
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="col s12 m10 l8 offset-m1 offset-l0 instructions-container">
              <h4>Instructions</h4>
              <div className="left-align instructions-text">
                <ReactMarkdown source={instructions} />
              </div>
            </div>
          </div>
          <div className="img-container col s12 m10 l8 offset-m1 offset-l2">
            <Image fluid={image.childImageSharp.fluid} />
          </div>
          <Link to="/recipes" className="btn back-to-recipes">
            Back To Recipes
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleRecipe($slug: String) {
    recipe: strapiRecipes(slug: { eq: $slug }) {
      instructions
      title
      description
      link
      ingredients {
        item
        id
        amount
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default ComponentName

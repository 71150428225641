import React, { useEffect, useState } from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import "./Hero.css"
const query = graphql`
  {
    file(relativePath: { eq: "hero.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const RecipeHero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  return (
    <BackgroundImage
      fluid={fluid}
      style={{ height: "50vh", zIndex: "-1" }}
      className="masthead"
    ></BackgroundImage>
  )
}

export default RecipeHero
